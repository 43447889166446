/* Reset to ensure no scrolling */
body, html {
  margin: 0;
  padding: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.screen-button {
  background-color: red;
  height: 30vh;
  width: 30vh;
  border: none;
}

.screen-button-test {
  background-color: orange;
  height: 30vh;
  width: 30vh;
  border: none;
}

.screen-button-GBeep {
  background-color: red;
  height: 30vh;
  width: 30vh;
  border: none;
}

.screen-button-ABeep {
  background-color: blue;
  height: 30vh;
  width: 30vh;
  border: none;
}

.screen-button-CBeep {
  background-color: #00ffff;
  height: 30vh;
  width: 30vh;
  border: none;
}


.screen-button-EBeep {
  background-color: #00FF00;
  height: 30vh;
  width: 30vh;
  border: none;
}


.screen-button-DBeep {
  background-color: #ff00ff;
  height: 30vh;
  width: 30vh;
  border: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.tv-container {
  position: relative;
  width: 120vw;  /* Reduced from 140vw */
  height: 100vh;
  background: #1a1a1a;
  overflow: visible;
  perspective: 1000px;
  padding: 20px;
  margin-left: -10vw;  /* Adjusted from -20vw */
  box-sizing: border-box;
}

.tv-screen {
  position: absolute;
  width: calc(15vw - 20px);  /* Reduced from 20vw */
  height: calc(12vw - 16px); /* Reduced from 16vw to maintain aspect ratio */
  cursor: pointer;
  transform-style: preserve-3d;
  transition: transform 0.3s;
}

/* Position TVs in a grid with limited overlap */
/* Row 1 */
.tv-screen:nth-child(1) { top: 8%; left: 17%; transform: rotate(-8deg); }
.tv-screen:nth-child(2) { top: 11%; left: 34%; transform: rotate(5deg); }
.tv-screen:nth-child(3) { top: 9%; left: 50%; transform: rotate(-5deg); }
.tv-screen:nth-child(4) { top: 8%; left: 68%; transform: rotate(7deg); }
.tv-screen:nth-child(5) { top: 10%; left: 85%; transform: rotate(-6deg); }

/* Row 2 */
.tv-screen:nth-child(6) { top: 34%; left: 20%; transform: rotate(6deg); }
.tv-screen:nth-child(7) { top: 32%; left: 37%; transform: rotate(-7deg); }
.tv-screen:nth-child(8) { top: 30%; left: 55%; transform: rotate(4deg); }
.tv-screen:nth-child(9) { top: 33%; left: 70%; transform: rotate(-8deg); }
.tv-screen:nth-child(10) { top: 31%; left: 87%; transform: rotate(5deg); }


/* Row 3 */
.tv-screen:nth-child(11) { top: 57%; left: 15%; transform: rotate(-5deg); }
.tv-screen:nth-child(12) { top: 55%; left: 32%; transform: rotate(6deg); }
.tv-screen:nth-child(13) { top: 57%; left: 50%; transform: rotate(-4deg); }
.tv-screen:nth-child(14) { top: 56%; left: 67%; transform: rotate(7deg); }
.tv-screen:nth-child(15) { top: 56%; left: 88%; transform: rotate(-6deg); }

/* Row 4 */
.tv-screen:nth-child(16) { top: 80%; left: 17%; transform: rotate(5deg); }
.tv-screen:nth-child(17) { top: 78%; left: 35%; transform: rotate(-7deg); }
.tv-screen:nth-child(18) { top: 79%; left: 55%; transform: rotate(6deg); }
.tv-screen:nth-child(19) { top: 80%; left: 73%; transform: rotate(-5deg); }
.tv-screen:nth-child(20) { top: 77%; left: 90%; transform: rotate(7deg); }

/* Hover effect only for interactive TVs */
.tv-screen.interactive:hover {
  transform: scale(1.05) translateZ(50px);
  z-index: 10;
}

.screen-content {
  position: absolute;
  top: 23%;
  left: 19%;
  width: 68%;
  height: 54%;
  overflow: hidden;
  background: #000;
  z-index: 2;
}

.static-effect_1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/static/static_practice.gif');
  opacity: 0.7;
  mix-blend-mode: screen;
  z-index: 3;
}
.static-effect_2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/static/static01.gif');
  opacity: 0.7;
  mix-blend-mode: screen;
  z-index: 3;
}
.static-effect_3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/static/static02.gif');
  opacity: 0.7;
  mix-blend-mode: screen;
  z-index: 3;
}
.static-effect_4 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/static/static03.gif');
  opacity: 0.7;
  mix-blend-mode: screen;
  z-index: 3;
}
.static-effect_5 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/static/staitc05.gif');
  opacity: 0.7;
  mix-blend-mode: screen;
  z-index: 3;
}

.color-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s;
}

.tv-screen.active .color-overlay {
  opacity: 0.7;
}

/* Add shadow for depth */
.tv-frame_1 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/frames/Computer01.png');
  background-size: cover;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  z-index: 4;
  pointer-events: none;
}

.tv-frame_2 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/frames/Computer02.png');
  background-size: cover;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  z-index: 4;
  pointer-events: none;
}

.tv-frame_3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/frames/Computer03.png');
  background-size: cover;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  z-index: 4;
  pointer-events: none;
}

.tv-frame_4 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url('../public/tv-assets/frames/Computer04.png');
  background-size: cover;
  box-shadow: 5px 5px 15px rgba(0,0,0,0.4);
  z-index: 4;
  pointer-events: none;
}

@keyframes screenFlicker {
  0% { opacity: 1; }
  2% { opacity: 0.8; }
  4% { opacity: 1; }
  50% { opacity: 1; }
  52% { opacity: 0.9; }
  54% { opacity: 1; }
}

@keyframes turnOn {
  0% { 
    transform: scaleY(0.01);
    opacity: 0;
  }
  50% { 
    transform: scaleY(0.01);
    opacity: 1;
  }
  100% { 
    transform: scaleY(1);
    opacity: 1;
  }
}

.tv-screen.active .screen-content {
  animation: turnOn 0.4s ease-out;
}

/* Add these new styles to your existing CSS */

/* Hover effect for all TVs */
.tv-screen:hover {
  transform: scale(1.05) translateZ(50px);
  z-index: 10;
}

/* Failure state */
.tv-screen.failure .color-overlay {
  opacity: 0.7;
  background-color: red !important;
  animation: failureFlash 0.5s ease-in-out infinite;
}

@keyframes failureFlash {
  0% { opacity: 0.7; }
  50% { opacity: 0.3; }
  100% { opacity: 0.7; }
}

/* Update your existing color-overlay class */
.tv-screen.active .color-overlay,
.tv-screen.failure .color-overlay {
  opacity: 0.7;
}

/* Add this new container style */
.tv-wrapper {
  position: relative;
  width: 100vw;  /* Changed from 90vw to full width */
  height: 100vh;
  margin: 0;  /* Remove margin */
  display: flex;
  justify-content: center;
  overflow: hidden;  /* Add this to prevent any overflow */
}

.tv-container {
  position: relative;
  width: 140vw;  /* Increase width */
  height: 100vh;
  background: #1a1a1a;
  overflow: visible;
  perspective: 1000px;
  padding: 20px;
  margin-left: -20vw;  /* Center the wider container */
  box-sizing: border-box;
}

/* Background video styles */
.background-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  z-index: 0;
  opacity: 0;
  pointer-events: none;
}

.background-video.playing {
  opacity: 1;
}

/* Test button styles */
.victory-test-button {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  padding: 10px 20px;
  background: #444;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Screen content adjustments for video */
.tv-screen.video-playing .screen-content {
  background: transparent !important;
}

.tv-screen.video-playing .static-effect {
  opacity: 0.3;
}

.tv-screen.video-playing .color-overlay {
  display: none;
}

/* Update video styles */
.screen-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 1;
}

.screen-video.playing {
  opacity: 1;
}

.screen-content {
  position: absolute;
  top: 23%;
  left: 19%;
  width: 68%;
  height: 54%;
  overflow: hidden;
  background: #000;
  z-index: 2;
}

.start-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a1a1a;
  z-index: 10;
}

.start-button {
  padding: 20px 40px;
  font-size: 24px;
  background: #333;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.start-button:hover {
  background: #444;
}

.victory-video-container {
  overflow: hidden;
  border-radius: 10px; /* Match TV screen border radius if any */
}

.victory-video {
  transform: scale(1.1); /* Slightly larger to avoid seeing edges during rotation */
  transform-origin: center center;
}

/* Update video-playing styles */
.tv-screen.video-playing .static-effect_1,
.tv-screen.video-playing .static-effect_2,
.tv-screen.video-playing .static-effect_3,
.tv-screen.video-playing .static-effect_4,
.tv-screen.video-playing .static-effect_5 {
  opacity: 0.3;
}

.tv-screen.video-playing .color-overlay {
  opacity: 0 !important;
}

.screen-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  z-index: 3;
  transition: opacity 0.3s;
}

/* When video is playing, show reflection on all screens */
.tv-screen.video-playing .screen-video {
  opacity: 1;
  transform: scale(1.01); /* Slight scale to prevent edge artifacts */
}
